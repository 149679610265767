import {
  Modal,
  ModalOverlay,
  ModalHeader,
  Text,
  ModalCloseButton,
  ModalBody,
  Box,
  ModalContent,
  useDisclosure,
  Divider,
} from "@chakra-ui/react";
import LogInForm from "./LogInForm";
import SignUpClientForm from "./SignUpClientForm";
import SignUpTherapistForm from "./SignUpTherapistForm";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SignUpChoiceCards from "./SignUpChoiceCards";
import {
  Button,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { MdClose } from "react-icons/md";

const LogInModal = ({
  onCloseModal = null,
  title = "",
  emailValidation = "true",
  signUpChoice = "login",
  noRedirect = false,
  nextURL = "",
  noClose = false,
  reverse = false,
  subtitle = null,
  hideTherapist = false,
  therapistName = "",
  bookingRequest = false,
}) => {
  const [showSignUp, setShowSignUp] = useState(signUpChoice);
  const [closeParent, setCloseParent] = useState(false);
  const loggedIn = useSelector((state) => state.user.JWTToken);

  const {
    isOpen: isLogInModalOpen,
    onClose: onLogInModalClose,
    onOpen: onLogInModalOpen,
  } = useDisclosure();

  useEffect(() => {
    if (loggedIn) {
      if (onCloseModal && isLogInModalOpen) {
        onCloseModal(true);
      }
      if (!noClose) {
        onLogInModalClose();
      }
    } else {
      onLogInModalOpen();
    }
  }, [
    isLogInModalOpen,
    loggedIn,
    onCloseModal,
    onLogInModalClose,
    onLogInModalOpen,
  ]);

  useEffect(() => {
    if (closeParent) {
      onLogInModalClose();
    }
  }, [onLogInModalClose, closeParent]);

  const renderLoginBtn = () => {
    return (
      <Box align="center" w="100%">
        <Divider />
        <Text py="20px" fontSize="0.75rem" color="brand.300">
          Already have an account?{" "}
          <Button
            className="border bg-white border-brand rounded-full text-brand hover:text-white hover:bg-brand transition px-4 py-2"
            onClick={() => {
              setShowSignUp("login");
            }}
          >
            Log in
          </Button>
        </Text>
      </Box>
    );
  };

  return (
    <Dialog
      open={isLogInModalOpen}
      onClose={(viaButton) => {
        if (!viaButton) {
          return;
        }
        if (onCloseModal !== null) {
          onCloseModal(false);
        }
        if (!noClose) {
          onLogInModalClose();
        }
      }}
      className="min-w-[90%] md:min-w-3/4 fixed z-[1001]"
    >
      <DialogBackdrop className="fixed inset-0 bg-black/30" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="max-w-3xl w-full border bg-white rounded-md max-h-[calc(100vh_-_40px)] overflow-auto px-4">
          <DialogTitle className="flex justify-between items-center px-4 pt-4 mb-4">
            <Text textStyle="h3">{title}</Text>
            <Button
              className="ml-2"
              onClick={() => {
                if (onCloseModal !== null) {
                  onCloseModal(false);
                }
                if (!noClose) {
                  onLogInModalClose();
                }
              }}
            >
              <MdClose className="w-6 h-6" />
            </Button>
          </DialogTitle>
          <div className="w-full mx-auto">
            <div className="pb-2">
              {showSignUp === "choice" && (
                <>
                  <SignUpChoiceCards setChoice={setShowSignUp} />
                  {renderLoginBtn()}
                </>
              )}

              {showSignUp === "client" && (
                <SignUpClientForm
                  setCloseParent={setCloseParent}
                  emailValidation={emailValidation}
                  noRedirect={noRedirect}
                  loginCallback={() => setShowSignUp("login")}
                />
              )}
              {showSignUp === "bookingRequest" && (
                <>
                  <SignUpClientForm
                    setCloseParent={setCloseParent}
                    emailValidation={emailValidation}
                    noRedirect={noRedirect}
                    loginCallback={() => setShowSignUp("login")}
                  />
                </>
              )}
              {showSignUp === "therapist" && (
                <>
                  <SignUpTherapistForm
                    noBorder
                    emailValidation={emailValidation}
                  />
                  {renderLoginBtn()}
                </>
              )}
              {showSignUp === "login" && (
                <div
                  className={`flex flex-col w-full items-stretch ${reverse ? "flex-col-reverse" : ""}`}
                >
                  <LogInForm
                    noBorder
                    noRedirect={noRedirect}
                    close={close}
                    emailValidation={emailValidation}
                    nextURL={nextURL}
                  />
                  {reverse && (
                    <div className="w-full flex justify-center">
                      <span className="font-bold text-brand-text py-2">
                        Log in to an existing account
                      </span>
                    </div>
                  )}
                  {reverse && (
                    <div className="w-full flex justify-center items-center gap-4">
                      <div className="flex-1 border-b border-b-brand-off" />
                      <span className="text-sm text-brand-alt py-2">Or</span>
                      <div className="flex-1 border-b border-b-brand-off" />
                    </div>
                  )}
                  <div className="flex flex-col w-full">
                    <div className="flex flex-col w-full items-center">
                      {!reverse && <Divider />}
                      <span
                        className={`text-brand-alt text-xs ${reverse ? "pt-2 pb-4" : "py-4"}`}
                      >
                        {subtitle ?? "Don't have an account yet?"}
                      </span>
                    </div>
                    <div className="w-full flex items-center gap-2 justify-between md:justify-center md:px-8 pb-4">
                      <Button
                        tabIndex="0"
                        className={`border bg-white border-brand rounded-full text-sm text-brand hover:text-white hover:bg-brand transition px-4 py-2 ${reverse ? "w-full" : ""}`}
                        onClick={(e) => {
                          setShowSignUp(
                            bookingRequest ? "bookingRequest" : "client"
                          );
                        }}
                      >
                        {reverse || bookingRequest
                          ? `Sign up now to contact ${therapistName}`
                          : "Client sign up"}
                      </Button>
                      {!hideTherapist && (
                        <Button
                          className="border bg-white border-brand rounded-full text-sm text-brand hover:text-white hover:bg-brand transition px-4 py-2"
                          onClick={() => {
                            setShowSignUp("therapist");
                          }}
                        >
                          Therapist sign up
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default LogInModal;
